<template>
    <div>
        <v-container>
            <div data-aos="fade-right">
                <h1 class="bigtitle">{{ $t('ourcustomers.title') }}</h1>
            </div>
            <div class="d-flex justify-center">
                <img style="max-width:40vw" contain class="ml-6 mt-4" alt="Under Construction"
                    src="@/assets/uc01.png" />
            </div>
        </v-container>
    </div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init()
export default {

}
</script>

<style>

</style>